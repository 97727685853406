@import '~antd/dist/antd.less';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Ubuntu;
  font-weight: 300;
  src: url(/fonts/ubuntu/ubuntu-300.woff) format('woff');
}
@font-face {
  font-family: Ubuntu;
  font-weight: 500;
  src: url(/fonts/ubuntu/ubuntu-500.woff) format('woff');
}
@font-face {
  font-family: Ubuntu;
  font-weight: 700;
  src: url(/fonts/ubuntu/ubuntu-700.woff) format('woff');
}
@font-face {
  font-family: Ubuntu;
  font-weight: 400;
  src: url(/fonts/ubuntu/ubuntu-regular.woff) format('woff');
}
// our custom variables
@dark-blue: #0274ba;
@dark-green: #19333f;
@light-green: #329c9c;

// override antd default
@breadcrumb-base-color: @dark-blue;
@breadcrumb-last-item-color: @dark-blue;
@tabs-highlight-color: @dark-green;
@tabs-ink-bar-color: @dark-green;
@tabs-hover-color: @dark-green;

// override for the Home icon positioning
svg {
  vertical-align: text-top;
}

